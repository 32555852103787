<template>
    <div>
        <b-row>
            <b-col>
                <b-card no-body>
                    <b-card-header>
                        <strong>Booked Travel</strong>
                    </b-card-header>
                    <b-card-body>
                        <b-row>
                            <b-col cols="4">
                                <b-form-group label-for="session-date-range" label="Session Start Date:">
                                    <picker-of-dates type="date"
                                                     id="session-date-range"
                                                     v-model="sessionDateRange"
                                                     format="MM/dd/yyyy"
                                                     placeholder="Select Date Range"
                                                     clearable range />
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="flight-date-range" label="Flight Date:">
                                    <picker-of-dates type="date"
                                                     id="flight-date-range"
                                                     v-model="flightDateRange"
                                                     format="MM/dd/yyyy"
                                                     placeholder="Select Date Range"
                                                     clearable range />
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="booked-date-range" label="Flight Booked:">
                                    <picker-of-dates type="date"
                                                     id="booked-date-range"
                                                     v-model="bookedDateRange"
                                                     format="MM/dd/yyyy"
                                                     placeholder="Select Date Range"
                                                     clearable range />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6">
                                <b-form-group label="Workshop(s):">
                                    <b-input-group size="sm">
                                        <b-select v-model="workshops" multiple :select-size="10" :options="workshopOptions"/>
                                        <b-input-group-addon>
                                            <b-button @click="workshops = []">
                                                <font-awesome-icon prefix="fas" icon="times"/>
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group label="Session(s):">
                                    <b-input-group size="sm">
                                        <b-select v-model="sessions" multiple :select-size="10" :options="sessionOptions"/>
                                        <b-input-group-addon>
                                            <b-button @click="sessions = []">
                                                <font-awesome-icon prefix="fas" icon="times"/>
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12">
                                <b-form-group label-for="search-organization-type" label="Organizations:">
                                    <b-input-group size="sm">
                                        <b-select id="search-organization-type"
                                                  v-model="organizationType"
                                                  :options="organizationTypeOptions">
                                            <template v-slot:first>
                                                <option :value="null"> - </option>
                                            </template>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button @click="organizationType = null">
                                                <font-awesome-icon icon="times" />
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                                <b-form-group label-for="search-organizations">
                                    <b-input-group size="sm">
                                        <b-select id="search-organizations"
                                                  v-model="organizations"
                                                  :options="organizationOptions"
                                                  :select-size="8"
                                                  multiple />
                                        <b-input-group-addon>
                                            <b-button @click="organizations = []">
                                                <font-awesome-icon prefix="fas" icon="times"/>
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <b-card-footer class="clearfix">
                        <b-button-group size="sm">
                            <b-button class="mr-2" variant="success" @click="doExport">Export</b-button>
                            <b-button variant="info" @click="clearFilters">Clear</b-button>
                        </b-button-group>
                    </b-card-footer>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>


<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import download from '@/util/downloader';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import PickerOfDates from '@/components/shared/PickerOfDates';
import _ from 'underscore';
import { date, mkDate } from '@/util/formatters';
import {errorModalOptions, errorToastOptions} from "../../../../util/formatters";


@Component({
    components: {
        PickerOfDates
    }
})
export default class BookedTravel extends Vue {
    sessionDateRange = [null,null];
    flightDateRange = [null,null];
    bookedDateRange = [null,null];
    workshops = [];
    sessions = [];
    organizationType = null;
    organizationOptions = [];
    organizations = [];

    get workshopOptions() {
        const options = _.map(this.$store.getters['common/workshops'], (ws) => {
            return {
                value: ws.id,
                text: ws.value
            }
        });
        // console.log(options);
        return options;
    }

    get sessionOptions() {
        const sessions = this.$store.getters['common/workshopSessionsWorkshopInstanceID'];
        const options = _.map(sessions, s => {
            return {
                text: s.value,
                value: s.id
            };
        });
        return options;
    }

    @Watch('workshops')
    async workshopsSelected(workshopIds) {
        this.sessions = [];
        await this.$store.dispatch('common/loadWorkshopSessionsWorkshopInstanceID', workshopIds)
            .catch(error => {
                this.$bvToast.toast(error.message, errorToastOptions);
            });
    }

    get organizationTypeOptions() {
        return [{
            value: 'trust-fund',
            text: 'Trust Fund(s)'
        }, {
            value: 'training-center',
            text: 'Training Center(s)'
        }, {
            value: 'council',
            text: 'Council(s)'
        }, {
            value: 'affiliate',
            text: 'Local(s)'
        }, {
            value: 'sct-contractor',
            text: 'Contractor(s)'
        }, {
            value: 'other',
            text: 'Other(s)'
        }];
    }

    @Watch('organizationType')
    organizationTypeSelected(orgType) {
        this.organizations = [];
        this.organizationOptions = [];
        switch (orgType) {
            case "trust-fund":
                this.loadTrustFunds();
                break;
            case "training-center":
                this.loadTrainingCenters();
                break;
            case "council":
                this.loadCouncils();
                break;
            case "affiliate":
                this.loadLocals();
                break;
            case "sct-contractor":
                this.loadContractors();
                break;
            case "other":
                this.loadOrganizations();
                break;
            default:
                // nuthin'
                break;
        }
    }

    loadTrainingCenters() {
        this.organizationOptions = _.map(this.$store.getters['common/trainingCenters'], (tc) => {
            return {
                value: tc.id,
                text: tc.value
            };
        });
    }

    loadTrustFunds() {
        this.organizationOptions = _.map(this.$store.getters['common/trustFunds'], (tf) => {
            return {
                value: tf.id,
                text: tf.value
            };
        });
    }

    loadCouncils() {
        const councils = _.groupBy(this.$store.getters['common/councils'], (c) => c.districtName);
        this.organizationOptions = _.map(councils, (cs, district) => {
            return {
                label: district,
                options: _.map(cs, (c) => {
                    return {
                        value: c.displayId,
                        text: sprintf('%s (%s)', c.name, c.displayId)
                    };
                })
            };
        });
    }

    loadLocals() {
        const locals = _.groupBy(this.$store.getters['common/locals'], (l) => sprintf("%s - %s", l.districtName, l.councilName));
        this.organizationOptions = _.map(locals, (ls, districtCouncil) => {
            return {
                label: districtCouncil,
                options: _.map(ls, (local) => {
                    return {
                        value: local.displayId,
                        text: sprintf("%s - %s, %s", local.displayId, local.city, local.state)
                    };
                })
            };
        });
    }

    loadContractors() {
        this.organizationOptions = _.map(this.$store.getters['organizations/getContractors'], (c) => {
            return {
                value: c.id,
                text: c.name
            };
        });
    }

    loadOrganizations() {
        this.organizationOptions = _.map(this.$store.getters['organizations/getOrganizations'], (c) => {
            return {
                value: c.id,
                text: c.name
            };
        });
    }

    clearFilters() {
        this.sessionDateRange = [null,null];
        this.flightDateRange = [null,null];
        this.bookedDateRange = [null,null];
        this.workshops = [];
        this.organizationType = null;
    }

    async doExport() {
        if (!this.isReady()) {
            return;
        }
        try {
            await download('POST', '/api/v1/export/booked-travel', JSON.stringify({
                sessionDateRange: this.sessionDateRange,
                flightDateRange: this.flightDateRange,
                flightBookedDateRange: this.bookedDateRange,
                workshopIds: this.workshops,
                sessionIds: this.sessions,
                organizationType: this.organizationType,
                organizationIds: this.organizations
            }));
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    isReady() {
        const nuthinIsSelected =
                !this.sessionDateRange[0] && !this.flightDateRange[0] && !this.bookedDateRange[0]
                && !this.workshops[0] && !this.sessions[0] && !this.organizations[0];

        if (nuthinIsSelected) {
            this.$bvModal.msgBoxOk('Please enter at least one search criteria.', {
                size: 'sm',
                buttonSize: 'sm'
            });
            return false;
        }
        return true;
    }

    destroyed() {
        //Clear any prior workshop sessions
        this.$store.dispatch('common/loadWorkshopSessionsWorkshopInstanceID', [])
            .catch(error => {
                this.$bvToast.toast(error.message, errorToastOptions);
            });
    }

    async mounted() {
        this.$store.commit('addBreadcrumb', Breadcrumb.create('Booked Travel', null, true));
        //Clear any prior workshop sessions
        this.$store.dispatch('common/loadWorkshopSessionsWorkshopInstanceID', [])
            .catch(error => {
                this.$bvToast.toast(error.message, errorToastOptions);
            });
        _.each([
            'common/loadWorkshops',
            'common/loadTrustFunds',
            'common/loadTrainingCenters',
            'common/loadCouncils',
            'common/loadLocals',
            'organizations/loadContractors',
            'organizations/loadOrganizations'
        ], (action) => {
            this.$store.dispatch(action)
                .catch(error => {
                    this.$bvToast.toast(error.message, errorToastOptions);
                });
        });
    }
}
</script>


<style scoped>

</style>